import { useState } from "react";
import { Form, Button, Typography, Input, Checkbox, notification } from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError } from "../utils/utilities";
import "./S3TaskVoiceAgent.css";

const { Title } = Typography;
const S3TaskVoiceAgent = (props) => {
  const [fileList, setFileList] = useState([]);
  const [waiting] = useState(props.waiting);
  const [enabledSubmit, setEnabledSubmit] = useState(true);
  const [tasks] = useState(props.tasks);
  const setTasks = props.setTasks;
  const handleCancel = props.handleCancel;
  const setWaiting = props.setWaiting;

  const clear = () => {
    setFileList([]);
    setEnabledSubmit(true);
    setWaiting(false);
    handleCancel();
  };

  const handleSubmit = async (values) => {
    setWaiting(true);
    let data_items = [];
    data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {
          customer: fetchDictValues(values, "customer"),
          patient_id: fetchDictValues(values, "patient_id"),
          patient_first_name: fetchDictValues(values, "patient_first_name"),
          patient_last_name: fetchDictValues(values, "patient_last_name"),
          patient_dob: fetchDictValues(values, "patient_dob"),
          questions_to_ask: {
            inpatient_care: fetchDictValues(
              values,
              "questions_to_ask_inpatient_care"
            ),
            wear_and_tear: fetchDictValues(
              values,
              "questions_to_ask_wear_and_tear"
            ),
            insurance_change: fetchDictValues(
              values,
              "questions_to_ask_insurance_change"
            ),
            usage: fetchDictValues(values, "questions_to_ask_usage"),
            last_seen_physician: fetchDictValues(
              values,
              "questions_to_ask_last_seen_physician"
            ),
            office_pickup: fetchDictValues(
              values,
              "questions_to_ask_office_pickup"
            ),
            quantity_on_hand: fetchDictValues(
              values,
              "questions_to_ask_quantity_on_hand"
            ),
            outside_supplies: fetchDictValues(
              values,
              "questions_to_ask_outside_supplies"
            ),
          },
          equipment: [
            {
              product: "seals",
              eligible: fetchDictValues(values, "equipment_seals_eligible"),
              quantity: fetchDictValues(values, "equipment_seals_quantity"),
              supply_period: fetchDictValues(
                values,
                "equipment_seals_supply_period"
              ),
            },
            {
              product: "pillows",
              eligible: fetchDictValues(values, "equipment_pillows_eligible"),
              quantity: fetchDictValues(values, "equipment_pillows_quantity"),
              supply_period: fetchDictValues(
                values,
                "equipment_pillows_supply_period"
              ),
            },
            {
              product: "cushions",
              eligible: fetchDictValues(values, "equipment_cushions_eligible"),
              quantity: fetchDictValues(values, "equipment_cushions_quantity"),
              supply_period: fetchDictValues(
                values,
                "equipment_cushions_supply_period"
              ),
            },
            {
              product: "nondisposable_filter",
              eligible: fetchDictValues(
                values,
                "equipment_nondisposable_filter_eligible"
              ),
              quantity: fetchDictValues(
                values,
                "equipment_nondisposable_filter_quantity"
              ),
              supply_period: fetchDictValues(
                values,
                "equipment_nondisposable_filter_supply_period"
              ),
            },
            {
              product: "disposable_filter",
              eligible: fetchDictValues(
                values,
                "equipment_disposable_filter_eligible"
              ),
              quantity: fetchDictValues(
                values,
                "equipment_disposable_filter_quantity"
              ),
              supply_period: fetchDictValues(
                values,
                "equipment_disposable_filter_supply_period"
              ),
            },
            {
              product: "water_chamber",
              eligible: fetchDictValues(
                values,
                "equipment_water_chamber_eligible"
              ),
            },
            {
              product: "chinstrap",
              eligible: fetchDictValues(values, "equipment_chinstrap_eligible"),
            },
            {
              product: "standard_tubing",
              eligible: fetchDictValues(
                values,
                "equipment_standard_tubing_eligible"
              ),
            },
            {
              product: "heated_tubing",
              eligible: fetchDictValues(
                values,
                "equipment_heated_tubing_eligible"
              ),
            },
            {
              product: "headgear",
              eligible: fetchDictValues(values, "equipment_headgear_eligible"),
            },
            {
              product: "mask",
              eligible: fetchDictValues(values, "equipment_mask_eligible"),
              product_name: fetchDictValues(values, "equipment_mask_product"),
              location: fetchDictValues(values, "equipment_mask_location"),
              size: fetchDictValues(values, "equipment_mask_size"),
            },
          ],
          insurances: [
            {
              insurance_company_name: fetchDictValues(
                values,
                "insurance_insurance_company_name"
              ),
              policy_number: fetchDictValues(values, "insurance_policy_number"),
              group_number: fetchDictValues(values, "insurance_group_number"),
              policy_start_date: null,
              policy_end_date: null,
              guarantor_relation: fetchDictValues(
                values,
                "insurance_guarantor_relation"
              ),
              primary_order: fetchDictValues(values, "insurance_primary_order"),
            },
          ],
          contact: {
            email: fetchDictValues(values, "patient_email"),
            phone: fetchDictValues(values, "patient_phone"),
            street_address: fetchDictValues(values, "patient_street_address"),
            street_address2: fetchDictValues(values, "patient_street_address2"),
            city: fetchDictValues(values, "patient_city"),
            state: fetchDictValues(values, "patient_state"),
            zip: fetchDictValues(values, "patient_zip"),
          },
          task_custom_fields: {
            expert_number: fetchDictValues(values, "expert_number"),
            objectives: fetchDictValues(values, "objectives"),
          },
          from_front_end: true,
        },
      },
    ];
    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    let createTask = {
      task_type: "voice_agent",
      data_items: data_items,
    };

    let formData = new FormData();
    formData.append("create_task", JSON.stringify(createTask));
    console.log(fileList);
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("uploads", file.originFileObj);
      });
    }
    AuthenticatedApi.post("/api/v2/task/create", formData)
      .then((response) => {
        if (response.data) {
          setTasks([response.data, ...tasks]);
        }
        clear();
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        clear();
      });
  };

  const fetchDictValues = (values, key) => {
    return values[key] !== undefined ? values[key] : s3Values[key];
  };

  const s3Values = {
    customer: "Synthpop DME",
    patient_first_name: "Taylor",
    patient_last_name: "Swift",
    patient_id: "ABC31415926",
    patient_dob: "01-01-2000",
    patient_email: "troubletroubletrouble@aol.com",
    patient_street_address: "123 Main St",
    patient_street_address2: "",
    patient_city: "Anytown",
    patient_state: "CA",
    patient_zip: "12345",
    caregivers_authorized: true,
    patient_phone: "+919029770085",
    expert_number: "+919029770085",
    questions_to_ask_inpatient_care: true,
    questions_to_ask_wear_and_tear: true,
    questions_to_ask_insurance_change: true,
    questions_to_ask_usage: true,
    questions_to_ask_last_seen_physician: true,
    questions_to_ask_office_pickup: true,
    questions_to_ask_outside_supplies: true,
    questions_to_ask_quantity_on_hand: true,
    physician_name: "Doogie Howser",
    physician_address_street_address: "1600 Pennsylvania Ave NW",
    physician_address_street_address2: "",
    physician_address_city: "Washington",
    physician_address_state: "DC",
    physician_address_zip: "20500",
    physician_phone: "",
    physician_fax: "",
    physician_npi: "",
    insurance_insurance_company_name: "Blue Cross Blue Shield",
    insurance_policy_number: "",
    insurance_group_number: "",
    insurance_policy_start_date: "",
    insurance_policy_end_date: "",
    insurance_guarantor_relation: "",
    insurance_primary_order: "1",
    equipment_seals_eligible: true,
    equipment_seals_quantity: "12",
    equipment_seals_supply_period: "90 day supply",
    equipment_cushions_eligible: false,
    equipment_cushions_quantity: "",
    equipment_cushions_supply_period: "",
    equipment_pillows_eligible: false,
    equipment_pillows_quantity: "",
    equipment_pillows_supply_period: "",
    equipment_disposable_filter_eligible: true,
    equipment_disposable_filter_quantity: "6",
    equipment_disposable_filter_supply_period: "90 day supply",
    equipment_nondisposable_filter_eligible: false,
    equipment_nondisposable_filter_quantity: "",
    equipment_nondisposable_filter_supply_period: "",
    equipment_water_chamber_eligible: false,
    equipment_chinstrap_eligible: false,
    equipment_standard_tubing_eligible: true,
    equipment_heated_tubing_eligible: false,
    equipment_mask_eligible: false,
    equipment_mask_product: "Airfit F30i",
    equipment_mask_location: "Under the nose",
    equipment_mask_size: "Medium",
    equipment_headgear_eligible: false,
    objectives: "NA",
  };

  return (
    <div>
      <Title className="gpt-form-title">AI Caller</Title>
      <p>Verify patient benefits by phone referencing their medical records.</p>
      <Form
        className="gpt-form-text"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={s3Values}
      >
        <h2>Customer Information</h2>
        <div className="form-row">
          <Form.Item
            name="customer"
            label="Customer"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
        <h2>Patient Information</h2>
        <div className="form-row">
          <Form.Item
            name="patient_first_name"
            label="First Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patient_last_name"
            label="Last Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patient_dob"
            label="Date of Birth"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            name="patient_id"
            label="Patient ID"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patient_email"
            label="Email"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patient_phone"
            label="Phone"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>

        <h2>Insurance Information</h2>
        <div className="form-row">
          <Form.Item
            name="insurance_insurance_company_name"
            label="Insurance Company Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="insurance_policy_number" label="Policy Number">
            <Input />
          </Form.Item>
          <Form.Item name="insurance_group_number" label="Group Number">
            <Input />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item name="insurance_policy_start_date" label="Start Date">
            <Input />
          </Form.Item>
          <Form.Item name="insurance_policy_end_date" label="End Date">
            <Input />
          </Form.Item>
          <Form.Item
            name="insurance_guarantor_relation"
            label="Guarantor Relation"
          >
            <Input />
          </Form.Item>
        </div>

        <h2>Physician Information</h2>
        <div className="form-row">
          <Form.Item
            name="physician_name"
            label="Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="physician_phone" label="Phone">
            <Input />
          </Form.Item>
          <Form.Item name="physician_fax" label="Fax">
            <Input />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            name="physician_address_street_address"
            label="Street Address"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="physician_address_city"
            label="City"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="physician_address_state"
            label="State"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="physician_address_zip"
            label="Zip"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>

        <h2>Equipment Eligibility and Quantity</h2>

        <div className="form-container">
          <div className="form-row equipment">
            <div className="form-column equipment">
              <h3>Seals</h3>
            </div>
            <div className="form-column">
              <Form.Item
                name="equipment_seals_eligible"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item name="equipment_seals_quantity">
                <Input placeholder="Quantity" />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item name="equipment_seals_supply_period">
                <Input placeholder="Supply Period" />
              </Form.Item>
            </div>
          </div>

          <div className="form-row equipment">
            <div className="form-column equipment">
              <h3>Pillows</h3>
            </div>
            <div className="form-column">
              <Form.Item
                name="equipment_pillows_eligible"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item name="equipment_pillows_quantity">
                <Input placeholder="Quantity" />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item name="equipment_pillows_supply_period">
                <Input placeholder="Supply Period" />
              </Form.Item>
            </div>
          </div>

          <div className="form-row">
            <div className="form-column equipment">
              <h3>Cushions</h3>
            </div>
            <div className="form-column">
              <Form.Item
                name="equipment_cushions_eligible"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item name="equipment_cushions_quantity">
                <Input placeholder="Quantity" />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item name="equipment_cushions_supply_period">
                <Input placeholder="Supply Period" />
              </Form.Item>
            </div>
          </div>

          <div className="form-row">
            <div className="form-column equipment">
              <h3>Disposable Filters</h3>
            </div>
            <div className="form-column">
              <Form.Item
                name="equipment_disposable_filter_eligible"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item name="equipment_disposable_filter_quantity">
                <Input placeholder="Quantity" />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item name="equipment_disposable_filter_supply_period">
                <Input placeholder="Supply Period" />
              </Form.Item>
            </div>
          </div>

          <div className="form-row">
            <div className="form-column equipment">
              <h3>Nondisposable Filters</h3>
            </div>
            <div className="form-column">
              <Form.Item
                name="equipment_nondisposable_filter_eligible"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item name="equipment_nondisposable_filter_quantity">
                <Input placeholder="Quantity" />
              </Form.Item>
            </div>
            <div className="form-column">
              <Form.Item name="equipment_nondisposable_filter_supply_period">
                <Input placeholder="Supply Period" />
              </Form.Item>
            </div>
          </div>
        </div>

        <h2>Questions to Ask</h2>
        <div className="form-row">
          <Form.Item
            name="questions_to_ask_inpatient_care"
            label="Inpatient Care"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_wear_and_tear"
            label="Wear and Tear"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_insurance_change"
            label="Insurance Change"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_usage"
            label="Usage"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_last_seen_physician"
            label="Last Seen Physician"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_office_pickup"
            label="Office Pickup"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_outside_supplies"
            label="Outside Supplies"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_quantity_on_hand"
            label="Quantity on Hand"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waiting}
            disabled={!enabledSubmit}
          >
            Place Call
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default S3TaskVoiceAgent;
